import { type RouteDefinition } from "@solidjs/router"

export const route = {
  load: () => {
    console.log("home load")
  },
} satisfies RouteDefinition
// v0 by Vercel.
// https://v0.dev/t/53AJyDmXxc3
export default function Home() {
  return (
    <div class="flex min-h-screen flex-col">
      <header class="flex h-14 items-center px-4 lg:px-6">
        <a class="flex items-center justify-center" href="#">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="h-6 w-6"
          >
            <path d="m16 13 5.223 3.482a.5.5 0 0 0 .777-.416V7.87a.5.5 0 0 0-.752-.432L16 10.5"></path>
            <rect x="2" y="6" width="14" height="12" rx="2"></rect>
          </svg>
          <span class="sr-only">视频剪辑</span>
        </a>
        <nav class="ml-auto flex gap-4 sm:gap-6">
          <a
            class="text-sm font-medium underline-offset-4 hover:underline"
            href="#"
          >
            功能
          </a>
          <a
            class="text-sm font-medium underline-offset-4 hover:underline"
            href="#"
          >
            定价
          </a>
          <a
            class="text-sm font-medium underline-offset-4 hover:underline"
            href="#"
          >
            关于我们
          </a>
          <a
            class="text-sm font-medium underline-offset-4 hover:underline"
            href="#"
          >
            联系我们
          </a>
        </nav>
      </header>
      <main class="flex-1">
        <section class="w-full border-y pt-12 md:pt-24 lg:pt-32">
          <div class="space-y-10 px-4 md:px-6 xl:space-y-16">
            <div class="mx-auto grid max-w-[1300px] gap-4 px-4 sm:px-6 md:grid-cols-2 md:gap-16 md:px-10">
              <div>
                <h1 class="lg:leading-tighter text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl xl:text-[3.4rem] 2xl:text-[3.75rem]">
                  终极视频编辑套件
                </h1>
              </div>
              <div class="flex flex-col items-start space-y-4">
                <p class="mx-auto max-w-[700px] text-gray-500 dark:text-gray-400 md:text-xl">
                  利用我们尖端的人工智能技术来简化您的视频编辑工作流程。轻松转录、编辑和优化您的视频。
                </p>
                <div class="space-x-4">
                  <a
                    class="inline-flex h-9 items-center justify-center rounded-md bg-gray-900 px-4 py-2 text-sm font-medium text-gray-50 shadow transition-colors hover:bg-gray-900/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-gray-50/90 dark:focus-visible:ring-gray-300"
                    href="/dashboard"
                  >
                    立即开始
                  </a>
                  <a
                    class="inline-flex h-9 items-center justify-center rounded-md border border-gray-200 bg-white px-4 py-2 text-sm font-medium shadow-sm transition-colors hover:bg-gray-100 hover:text-gray-900 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 dark:border-gray-800 dark:bg-gray-950 dark:hover:bg-gray-800 dark:hover:text-gray-50 dark:focus-visible:ring-gray-300"
                    href="#"
                  >
                    按钮
                  </a>
                </div>
              </div>
            </div>
            <img
              src="/placeholder.svg"
              width="1270"
              height="300"
              alt="英雄"
              class="mx-auto aspect-[3/1] overflow-hidden rounded-t-xl object-cover"
            />
          </div>
        </section>
        <section class="w-full py-12 md:py-24 lg:py-32">
          <div class="container space-y-12 px-4 md:px-6">
            <div class="flex flex-col items-center justify-center space-y-4 text-center">
              <div class="space-y-2">
                <div class="inline-block rounded-lg bg-gray-100 px-3 py-1 text-sm dark:bg-gray-800">
                  关键功能
                </div>
                <h2 class="text-3xl font-bold tracking-tighter sm:text-5xl">
                  革新您的视频编辑体验
                </h2>
                <p class="max-w-[900px] text-gray-500 dark:text-gray-400 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                  我们尖端的人工智能技术将彻底改变您编辑视频的方式，节省您的时间和精力。
                </p>
              </div>
            </div>
            <div class="mx-auto grid items-start gap-8 sm:max-w-4xl sm:grid-cols-2 md:gap-12 lg:max-w-5xl lg:grid-cols-3">
              <div class="grid gap-1">
                <h3 class="text-lg font-bold">基于文本的视频编辑</h3>
                <p class="text-sm text-gray-500 dark:text-gray-400">
                  将您的视频转换为可编辑的文本，直接进行修改，简化编辑流程。
                </p>
              </div>
              <div class="grid gap-1">
                <h3 class="text-lg font-bold">自动去除停顿</h3>
                <p class="text-sm text-gray-500 dark:text-gray-400">
                  我们的人工智能可自动识别并去除您视频中的停顿，节省您的时间和精力。
                </p>
              </div>
              <div class="grid gap-1">
                <h3 class="text-lg font-bold">智能视频优化</h3>
                <p class="text-sm text-gray-500 dark:text-gray-400">
                  我们的人工智能可检测并消除重复或错误的片段，确保最终产品流畅优雅。
                </p>
              </div>
            </div>
            <div class="flex flex-col items-start justify-center gap-4 sm:flex-row">
              <a
                class="inline-flex h-10 items-center justify-center rounded-md bg-gray-900 px-8 text-sm font-medium text-gray-50 shadow transition-colors hover:bg-gray-900/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-gray-50/90 dark:focus-visible:ring-gray-300"
                href="/dashboard"
              >
                立即开始
              </a>
              <a
                class="inline-flex h-10 items-center justify-center rounded-md border border-gray-200 bg-white px-8 text-sm font-medium shadow-sm transition-colors hover:bg-gray-100 hover:text-gray-900 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 dark:border-gray-800 dark:bg-gray-950 dark:hover:bg-gray-800 dark:hover:text-gray-50 dark:focus-visible:ring-gray-300"
                href="#"
              >
                了解更多
              </a>
            </div>
          </div>
        </section>
        <section class="w-full py-12 md:py-24 lg:py-32">
          <div class="container px-4 md:px-6">
            <div class="mx-auto grid max-w-5xl items-center gap-6 py-12 lg:grid-cols-2 lg:gap-12">
              <div class="flex flex-col justify-center space-y-4">
                <ul class="grid gap-6">
                  <li>
                    <div class="grid gap-1">
                      <h3 class="text-xl font-bold">基于文本的视频编辑</h3>
                      <p class="text-gray-500 dark:text-gray-400">
                        将您的视频转换为可编辑的文本，直接进行修改，简化编辑流程。
                      </p>
                    </div>
                  </li>
                  <li>
                    <div class="grid gap-1">
                      <h3 class="text-xl font-bold">自动去除停顿</h3>
                      <p class="text-gray-500 dark:text-gray-400">
                        我们的人工智能可自动识别并去除您视频中的停顿，节省您的时间和精力。
                      </p>
                    </div>
                  </li>
                  <li>
                    <div class="grid gap-1">
                      <h3 class="text-xl font-bold">智能视频优化</h3>
                      <p class="text-gray-500 dark:text-gray-400">
                        我们的人工智能可检测并消除重复或错误的片段，确保最终产品流畅优雅。
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <img
                src="/placeholder.svg"
                width="550"
                height="310"
                alt="图片"
                class="mx-auto aspect-video overflow-hidden rounded-xl object-cover object-center sm:w-full lg:order-first"
              />
            </div>
          </div>
        </section>
        <section class="w-full bg-gray-100 py-12 dark:bg-gray-800 md:py-24 lg:py-32">
          <div class="container grid items-center justify-center gap-4 px-4 text-center md:px-6">
            <div class="space-y-3">
              <h2 class="text-3xl font-bold tracking-tighter md:text-4xl/tight">
                体验视频编辑的未来
              </h2>
              <p class="mx-auto max-w-[600px] text-gray-500 dark:text-gray-400 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                今天就尝试我们的人工智能视频编辑套件，亲身感受它带来的不同体验。
              </p>
            </div>
            <div class="mx-auto w-full max-w-sm space-y-2">
              <form class="flex space-x-2">
                <input
                  class="flex h-10 w-full max-w-lg flex-1 rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                  type="email"
                  placeholder="输入您的电子邮件"
                />
                <button
                  class="inline-flex h-10 items-center justify-center whitespace-nowrap rounded-md bg-primary px-4 py-2 text-sm font-medium text-primary-foreground ring-offset-background transition-colors hover:bg-primary/90 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
                  type="submit"
                >
                  立即开始
                </button>
              </form>
              <p class="text-xs text-gray-500 dark:text-gray-400">
                注册以获取早期访问权限和独家优惠。{" "}
                <a class="underline underline-offset-2" href="#">
                  条款和条件
                </a>
              </p>
            </div>
          </div>
        </section>
      </main>
      <footer class="flex w-full shrink-0 flex-col items-center gap-2 border-t px-4 py-6 sm:flex-row md:px-6">
        <p class="text-xs text-gray-500 dark:text-gray-400">
          © 2024 视频剪辑。保留所有权利。
        </p>
        <nav class="flex gap-4 sm:ml-auto sm:gap-6">
          <a class="text-xs underline-offset-4 hover:underline" href="#">
            服务条款
          </a>
          <a class="text-xs underline-offset-4 hover:underline" href="#">
            隐私
          </a>
        </nav>
      </footer>
    </div>
  )
}
